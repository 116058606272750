/* PANELS */

/* backgrounds */

.bg-gray-extra-light,
.container-fluid.bg-gray-extra-light { background: $gray-extra-light; }
.bg-gray-light,
.container-fluid.bg-gray-light { background: $gray-light; }
.bg-gray,
.container-fluid.bg-gray { background: $gray; }
.bg-gray-dark,
.container-fluid.bg-gray-dark { background: $gray-dark; }
.bg-terracotta-extra-light,
.container-fluid.bg-terracotta-extra-light { background: $terracotta-extra-light; }
.bg-maroon,
.container-fluid.bg-maroon { background: $maroon; }
.bg-brick,
.container-fluid.bg-brick { background: $brick; }
.bg-gold,
.container-fluid.bg-gold { background: $gold; }
.bg-patina,
.container-fluid.bg-patina { background: $patina; }
.bg-overlay,
.container-fluid.bg-overlay { background: $bg-overlay; }
.bg-none,
.container-fluid.bg-none { background: none; }

/* bg-maroon added - nested elements */

.bg-maroon h6, .bg-maroon h5, .bg-maroon h4, .bg-maroon h3, .bg-maroon h2, .bg-maroon h1,
.bg-maroon p, .bg-maroon .flex-item.content p,
.bg-maroon p em, .bg-maroon .flex-item.content p em, .bg-maroon p strong, .bg-maroon .flex-item.content p strong
.bg-brick h6, .bg-brick h5, .bg-brick h4, .bg-brick h3, .bg-brick h2, .bg-brick h1, .bg-brick p,
.bg-gray h6, .bg-gray h5, .bg-gray h4, .bg-gray h3, .bg-gray h2, .bg-gray h1, .bg-gray p,
.bg-gray-dark h6, .bg-gray-dark h5, .bg-gray-dark h4, .bg-gray-dark h3, .bg-gray-dark h2, .bg-gray-dark h1, .bg-gray-dark p,
.bg-patina h6, .bg-patina h6, .bg-patina h6, .bg-patina h6, .bg-patina p,
.bg-patina-dark h6, .bg-patina-dark h6, .bg-patina-dark h6, .bg-patina-dark h6, .bg-patina-dark p,
.bg-overlay h6, .bg-overlay h6, .bg-overlay h6, .bg-overlay h6, .bg-overlay p  { color: $gray-extra-light; }

.bg-maroon a.cta, .bg-brick a.cta, .bg-gray a.cta, .bg-gray-dark a.cta, .bg-patina a.cta, .bg-patina-dark a.cta, .bg-overlay a.cta  {
  color: lighten($gold, 15%);
  &:hover {
    color: lighten($gold, 30%) !important;
  }
  &:visited {
    color: $gold !important;
  }
}

.bg-gray-extra-light h6, .bg-gray-extra-light h5, .bg-gray-extra-light h4,
.bg-gray-extra-light h3, .bg-gray-extra-light p,
.bg-gold h6, .bg-gold h5, .bg-gold h4, .bg-gold h3, .bg-gold p,
.bg-terracotta-extra-light h6, .bg-terracotta-extra-light h5,
.bg-terracotta-extra-light h4, .bg-terracotta-extra-light h3,
.bg-terracotta-extra-light p { color: $black !important; }

.bg-gold a.cta, .bg-terracotta-extra-light a.cta  {
  color: $maroon !important;
  &:hover {
    color: lighten($maroon, 10%) !important;
  }
}

/* aspect-ratio */

// .aspect-ratio-sixteen-nine { aspect-ratio: 16/9; } /* 1000 x 560 image interior inline - video */
// .aspect-ratio-four-one { aspect-ratio: 4/1; } /* 1800 x 450 - hero shallow - interior */
// .aspect-ratio-three-one { aspect-ratio: 3/1; } /* 1800 x 600 - hero standard - homepage */
// .aspect-ratio-ten-four { aspect-ratio: 10/4; } /* 1800 x 720 - hero standard - homepage */
// .aspect-ratio-four-three { aspect-ratio: 4/3; } /* 1000 x 750 - old video */
// .aspect-ratio-one-one { aspect-ratio: 1/1; } /* 1000 x 1000 - portraits - profiles */
//
//
// .aspect-ratio-sixteen-nine,
// .aspect-ratio-four-one,
// .aspect-ratio-three-one,
// .aspect-ratio-ten-four,
// .aspect-ratio-four-three,
// .aspect-ratio-one-one {
//   @include tablet-portrait {
//     height: 100%;
//     min-height: 0;
//     overflow: visible;
//   }
// }

#main-section-wrapper #panel-intro .container.container-80 {
  width: 80%;
}

/* introduction paragraph */

.intro-paragraph.sans-serif {
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
}

/* panel banner */

#panel-banner .page-banner {
  margin: 0 auto 1rem;
  padding: .75rem 1rem;
  width: 350px;
  background: #8d0034;
}
#panel-banner .page-banner .page-banner-bullets {
  display: block;
  margin: 0 0 .025rem;
  width: 100%;
  height: 0.75rem;
  font-family: 'Times', serif;
  font-size: 1.75rem;
  letter-spacing: .125rem;
  line-height: 0;
  color: #febc18;
}
#panel-banner .page-banner h4 {
  margin: .25rem 0 0;
  width: 100%;
  font-family: 'Antenna Comp Bold', sans-serif;
  font-size: 2.25rem;
  letter-spacing: .125rem;
  word-spacing: .125rem;
  color: #ffffff;
  text-transform: uppercase;
}

/* accent bar */

.accent-bar {
  position: absolute;
  top: -.75rem;
  margin: 0;
  padding: 0;
  width: 100%;
  height: .75rem;
  background: $maroon;
}

/* announcement */

.panel-announcement {
  float: left;
  margin: 0;
  padding: 1rem;
  width: 100%;
  height: auto;
  animation-delay: 0;
  background: $maroon;
  p {
    margin: .25rem 0 0 0;
    padding: 0;
    height: auto;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: $white;
    text-align: center;
    animation-delay: 0;
    -webkit-animation: slide-in 1.5s;
    -moz-animation: slide-in 1.5s;
    a.cta {
      display: inline;
      font-size: 85%;
      color: $gold !important;
      -webkit-animation: slide-in 3.5s;
      -moz-animation: slide-in 3.5s;
    }
  }
}
@-webkit-keyframes slide-in {
  0% { opacity: 0; -webkit-transform: translateY(100%); }
100% { opacity: 1; -webkit-transform: translateY(0); }
}
@-moz-keyframes slide-in {
  0% { opacity: 0; -moz-transform: translateY(100%); }
100% { opacity: 1; -moz-transform: translateY(0); }
}

/* cards */

#panel-cards .grid-wrapper-min-max {
  grid-gap: 1rem;
  .grid-item {
    margin: 0;
  }
}

#panel-cards #facultyList.grid-wrapper-min-max .grid-item.filterRow,
#panel-cards #facultyList.grid-wrapper-min-max .grid-item.category {
  display: flex;
}

#panel-numbers .grid-wrapper-min-max .grid-item {
  margin: 0;
}

#main-section-wrapper #panel-numbers {
  .container.container-100 .grid-wrapper-min-max {
    width: 100% !important;
  }
  .container.container-80 .grid-wrapper-min-max {
    width: 80% !important;
  }
  .container.container-50 .grid-wrapper-min-max {
    width: 50% !important;
  }
}

/* card-image-overlay bg-maroon added */

#card-image-overlay .content-grid-wrapper.bg-maroon .flex-item.links { background: $maroon !important; }
#card-image-overlay .content-grid-wrapper.bg-maroon .flex-item.links h6,
#card-image-overlay .content-grid-wrapper.bg-maroon .flex-item.links h5,
#card-image-overlay .content-grid-wrapper.bg-maroon .flex-item.links p,
#card-image-overlay .content-grid-wrapper.bg-maroon .flex-item.links p a {
  color: $gray-extra-light !important;
}

#card-image-overlay .content-grid-wrapper.bg-maroon .flex-item.links p a {
  &:hover {
    color: $gold !important;
  }
}

#card-image-overlay .content-grid-wrapper.bg-maroon .flex-item.links button {
  background: $gold !important;
  color: black;
  &:hover {
    background: $opus !important;
  }
}

a {
  transition: color .3s ease-in-out;
}

.container-fluid {
  position: relative;
  background: none;
  z-index: 1;
}

/*
img {
  display: block;
  margin: 0;
  width: 100%;
}
*/

hr {
  display: block;
  margin: 1.5rem 0;
  border-bottom: 1px solid darken($gray-extra-light, 5%);
  width: 100%;
  height: 0;
}

/* table */

tr:nth-child(even) { background: $white; }
tr:nth-child(odd) { background: $gray-extra-light; }

/* image */

#panel-image img {
  display: block;
  margin: 0;
  width: 100%;
}

/* image - lead */
/* image - lead - RESIZE */
/* h1 styled as h4 in mobile */

#panel-image {
  @include phone-landscape {
    height: 100%;
  }
}

#panel-image.hero-image-lead img {
  display: block;
  margin: 0;
  width: 100%;
}

#panel-image, #panel-image-lead-resize {
  @include tablet-portrait {
    .grid-item {
      margin: 0;
    }
  }
  .hero-image-content {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 2rem 10% 2.25rem;
    width: 100%;
    color: $white;
    background: $bg-overlay-dark;
    h1 {
      line-height: 3;
      line-height: rvr(3);
    }
    h2 {
      line-height: 2.25;
      line-height: rvr(2.25);
    }
    h1, h2, h3, h5, p, .intro-paragraph p {
      color: $white;
      @include tablet-portrait {
        color: $white;
      }
    }
    @include tablet-portrait {
      position: relative;
      padding: 2rem 5%;
      color: $white;
      background: $maroon;
    }
    h1, h2 {
      margin-bottom: 1.25rem;
      @include tablet-portrait {
        margin-bottom: .5rem;
        font-family: 'Antenna Condensed Bold', sans-serif;
        font-size: 2.5rem;
      }
    }
    h3 {
      margin-top: .5rem;
    }
    p {
      margin-bottom: 0;
      font-family: 'Open Sans', sans-serif;
      font-size: 1.5rem;
      line-height: rvr(2);
      color: $white;
      @include tablet-portrait {
        margin-bottom: .5rem;
        font-size: 1.25rem;
        line-height: rvr(1.85);
        color: $black;
      }
    }
    a.cta, p a.cta {
      display: inline;
      color: lighten($gold, 10%) !important;
      font-size: 85%;
      text-transform: uppercase;
      @include tablet-portrait {
        margin-top: .75rem;
        display: block;
      }
    }
  }
}

#panel-image.hero-image-lead.hero-image-only {
  .grid-wrapper-min-max .grid-item {
    display: none;
  }
}

#panel-image.full-overlay {
  display: grid;
  height: 100%;
  overflow: hidden;
  img {
    display: flex;
    grid-column-start: 1;
    grid-row-start: 1;
    height: 100%;
    max-height: 100vh;
    width: 100vw;
    object-fit: cover;
  }
}

#panel-image.full-overlay > div.grid-wrapper-min-max {
  display: flex;
  grid-row-start: 1;
  grid-column-start: 1;
  height: 100%;
}

#panel-image.hero-image-lead.full-overlay .hero-image-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  top: 0;
  padding-top: 5rem !important;
  height: 100%;
  align-items: start;
  justify-content: start;
  background: rgba(0,0,0,.7);
  h1, h3, h6, p, a {
    width: 100%;
    text-align: center;
  }
}

#panel-image.hero-image-lead .grid-wrapper-min-max .grid-item:first-of-type,
#panel-image-lead-resize .grid-wrapper-min-max .grid-item:first-of-type {}

/* image - lead - RESIZE */
/* background images - various heights - 450 - 800 - 1000 */

#image-resize-background-image.hero-wrapper.hero-wrapper-450 {
  background-image: url('../images/images-resize/resize-chicago-skyline-1800-450.jpg');
}
#image-resize-background-image.hero-wrapper.hero-wrapper-800 {
  background-image: url('../images/images-resize/resize-cudahy-rainbow-1800-800.jpg');
}
#image-resize-background-image.hero-wrapper.hero-wrapper-1000 {
  background-image: url('../images/images-resize/resize-reader-lake-1800-1000.jpg');
}

/* buttons */

#panel-buttons .grid-wrapper-min-max {
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  @include tablet-portrait {
    display: block;
    width: 100%;
  }
}

#panel-button-cta {
  padding: .75rem 0 .85rem;
  h3, p {
    color: $black;
  }
  h3 {
    margin-top: 0;
  }
  h5 {
    display: inline;
    margin: 0 1rem 0 0;
    color: $black;
  }
  button {
    display: inline;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    max-width: 50%;
    color: $gray-extra-dark !important;
  }
  .button-maroon button {
    color: $white !important
  }
  h6 {
    display: none;
    line-height: 1;
  }
  .bg-gold h3,
  .bg-gray-extra-light h3,
  .bg-terracotta-extra-light h3,
  .bg-gold p,
  .bg-gray-extra-light p,
  .bg-terracotta-extra-light p {
    color: $black !important;
  }
  p:last-of-type {
    margin-bottom: 0;
  }
}
#panel-button-cta.bg-maroon h5,
#panel-button-cta.bg-maroon h3,
#panel-button-cta.bg-maroon p,
#panel-button-cta.bg-maroon p strong {
  color: $white !important;
}
#panel-button-cta.bg-gold button,
#panel-button-cta.bg-gray button,
#panel-button-cta.bg-terracotta-extra-light button {
  color: $white !important;
  background: $maroon !important;
}
#panel-button-cta.bg-maroon button,
#panel-button-cta.bg-brick button {
  color: $black !important;
  background: $gold !important;
}

/* links - multiple */

#panel-intro .grid-wrapper-min-max #panel-links .grid-wrapper-min-max {
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  grid-template-areas: none !important;
}

#panel-links .container {
  margin: 0;
  padding: 0;
  width: 100%;
}

#panel-links {
  ul {
  margin: 0 !important;
  padding: 0;
    li {
    margin: 0;
    padding: 0;
    list-style: none;
    text-indent: 0 !important;
      a {
        display: block;
        margin: .125rem auto 1.125rem;
        width: 100%;
        font-family: 'Antenna Condensed Bold', sans-serif;
        font-size: 1.15rem;
        font-weight: 800;
        line-height: 1.25;
        line-height: rvr(1.25);
        text-transform: none !important;
        text-decoration: none;
        &:hover {
          color: $brick;
        }
      }
    }
  }
}

#main-section-wrapper #panel-links .container {
  width: 100% !important;
  ul li a {
    font-size: 1.05rem;
    line-height: 1.35;
  }
}

/* image - caption */
/* image - caption - RESIZE */

#panel-image.hero-image-content img {
  margin: 0;
}

#panel-image.hero-image-caption,
#panel-image-caption-resize.hero-image-caption {
  .grid-item {
    margin: 0 !important;
  }
  img {
    margin-bottom: 0;
  }
  .hero-image-content {
    position: relative;
    padding: 2rem;
    background: $gray-extra-light;
    h4, h6, p {
      color: $black;
    }
    h4 {
    margin-bottom: .65rem;
    font-size: 1.65rem;
    line-height: rvr(1.65);
    }
    p {
      font-family: 'Open Sans', sans-serif;
      font-size: 1.25rem;
    }
    a.cta {
      color: $maroon !important;
      &:hover {
        color: $brick !important;
      }
    }
    @include tablet-portrait {
      h4 {
        font-size: 1.75rem;
      }
      p {
        font-size: 1.125rem;
        line-height: 1.85rem;
      }
    }
  }
}

#panel-image.hero-image-lead,
#panel-image-caption-resize.hero-image-lead {
  .hero-image-content {
    p a, p a.cta {
      font-size: 80%;
      color: $gold;
      text-transform: uppercase;
      background-image: linear-gradient(120deg, #000000 0%, #000000 100%);
      background-repeat: no-repeat;
      background-size: 106% 0.075rem;
      background-position: -3% 103%;
      transition: all 0.25s ease-in;
      &:hover {
        background-size: 100% 106%;
      }
    }
  }
}

#panel-image.hero-image {
  .hero-image-content {
    display: none;
  }
}

/* text - called */

#panel-text.called-banner {
  padding: 1rem 1rem .95rem;
  border: 0 !important;
}

/* image - text */
#panel-image-text .grid-wrapper-grid-area {
  display: -ms-grid;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr !important;
  -ms-grid-columns: 1fr 1fr 1fr 1fr !important;
  grid-template-rows: auto;
  grid-auto-flow: row;
  -ms-grid-rows: auto;
  grid-auto-rows: auto;
  grid-row-gap: 0;
  width: 100%;
  grid-gap: 0;
  .grid-area {
  position: relative;
  }
  img {
    margin: 0 !important;
  }
}
.grid-area-image {
grid-area: 1 / 1 / span 1 / span 2;
@include tablet-portrait {
  grid-area: 1 / 1 / span 1 / span 4;
}
}
.grid-area-content {
grid-area: 1 / 3 / span 1 / span 2;
@include tablet-portrait {
  grid-area: 2 / 1 / span 1 / span 4;
}
}

#panel-image-text .grid-wrapper-grid-area.toggle-grid-area {
  .grid-area-image {
  grid-area: 1 / 3 / span 1 / span 2;
  @include tablet-portrait {
    grid-area: 1 / 1 / span 1 / span 4;
  }
  }
  .grid-area-content {
  grid-area: 1 / 1 / span 1 / span 2;
  @include tablet-portrait {
    grid-area: 2 / 1 / span 1 / span 4;
  }
  }
}

#panel-image-text img {
  margin: 0;
}

#panel-image-text {
  .grid-wrapper-grid-area {
    grid-gap: 0;
    .grid-area {
      margin: 0;
      @include phone-portrait {
        margin: 0 !important;
      }
    }
  }
  .content-image-text-image {
    padding-top: 100%;
    height: 100%;
    overflow: hidden;
    background-image: url('../images/images-image-grid-highlights/highlight-cudahy-detail-2000x2000.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    img {
      display: none;
    }
  }
  .content-image-text {
    padding: 4rem;
    height: 100%;
    contain: size;
    overflow: auto;
    background: $gray-extra-light;
    @include phone-portrait {
      contain: content;
    }
    h6, h4, p, p em, p strong {
      color: $black !important;
    }
  }
  p {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.125rem;
  }
}

#panel-image-text.bg-maroon,
#panel-image-text.bg-gray,
#panel-image-text.bg-gray-dark,
#panel-image-text.bg-patina,
#panel-image-text.bg-patina-dark {
  .content-image-text {
    h6, h4, p, p em, p strong, li, li strong {
      color: $gray-extra-light !important;
    }
  }
}

#panel-image-text.bg-maroon .content-image-text {
  background: $maroon !important;
}
#panel-image-text.bg-white .content-image-text {
  background: $white !important;
}
#panel-image-text.bg-gray-extra-light .content-image-text {
  background: $gray-extra-light !important;
}
#panel-image-text.bg-gray-light .content-image-text {
  background: $gray-light !important;
}
#panel-image-text.bg-gray .content-image-text {
  background: $gray !important;
}
#panel-image-text.bg-gray-dark .content-image-text {
  background: $gray-dark !important;
}
#panel-image-text.bg-patina .content-image-text {
  background: $patina !important;
}
#panel-image-text.bg-patina-dark .content-image-text {
  background: $patina-dark !important;
}

#panel-image-text .content-image-text-image img::before {
  height: 100%;
}
#panel-image-text .content-image-text-image.show-full-image {
  background-image: none !important;
  padding-top: 0;
  img {
    display: block !important;
  }
}

/* image-overlay - used on image grid - image highlights - image-overlay > blockquote */

.grid-item .content-image-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 0;
  padding: 2rem;
  color: $white !important;
  background: $bg-overlay-extra-dark;
  opacity: 0;
  transition: .35s ease;
  overflow: hidden;
  h3.teaser, p {
    margin: auto;
    width: 80%;
    height: auto;
    max-height: 7.5rem;
    font-family: 'Antenna Condensed Bold', sans-serif;
    font-size: 1.5rem;
    line-height: calc(var(--baseline) * 1.65 * 1rem);
    overflow: scroll;
    @include tablet-portrait {
      height: auto;
      max-height: 4rem;
      font-size: 1.5rem;
      line-height: 1;
    }
    @include phone-portrait {
      height: auto;
    }
  }
  h4 {
    font-size: 1.85rem;
  }
  @include tablet-portrait {
    h4 {
      font-size: 1.75rem;
    }
  }
  p {
    font-family: 'Open Sans', sans-serif;
    color: $white;
  }
  a.cta {
    color: $gold !important;
    &:hover {
      color: $gold-light !important;
    }
  }
  @include phone-portrait {
    blockquote {
      margin: 0;
      padding: 1rem 0;
      border: 0;
    }
  }
}

.content-image-overlay.content-image-overlay-40 {
  background: $bg-overlay-light !important;
}

.content-image-overlay.content-image-overlay-50 {
  background: $bg-overlay !important;
}

.content-image-overlay.content-image-overlay-60 {
  background: $bg-overlay-dark !important;
}

.content-image-overlay.content-image-overlay-70 {
  background: $bg-overlay-extra-dark !important;
}

/* image - overlay - symbol is arrow up */

button.content-activate-symbol {
  display: none;
}
button.content-activate-symbol {
  position: absolute;
  display: block;
  margin: 0;
  right: 2rem;
  bottom: 1.25rem;
  padding: 0;
  width: 2.5rem !important;
  height: 2.5rem !important;
  font-family: 'Arial', sans-serif;
  font-weight: normal;
  font-size: 2rem;
  text-align: center;
  color: $gray-extra-light !important;
  border: solid 3px $gray-extra-light !important;
  border-radius: 1.5em;
  background-color: $bg-overlay;
  background-image: url('https://www.luc.edu/media/luc-template-2020/images/ui-arrow-up@2x.png');
  background-repeat: no-repeat;
  background-position: .5rem .5rem;
  background-size: 1.125rem;
  a {
    color: $gray-extra-light;
  }
  .active {
    transition: transform .5s;
    transform: rotate(180deg);
    color: $gray-extra-light;
    background-color: $bg-overlay-dark !important;
    background-image: url('https://www.luc.edu/media/luc-template-2020/images/ui-arrow-up@2x.png');
    background-repeat: no-repeat;
    background-position: .5rem .5rem;
    background-size: 1.125rem;
    cursor: default;
  }
  &:focus {
    border-radius: 1.5em !important;
    outline: 3px solid #febc18;
    outline-offset: -3px;
  }
button#content-activate-symbol-overlay.content-activate-symbol.active {
  transform: rotate(180deg) !important;
  background-image: url('https://www.luc.edu/media/luc-template-2020/images/ui-arrow-up@2x.png');
  background-position: .5rem .5rem;
  background-size: 1.125rem;
}
  // &:active {
  //   border-radius: 1.5em !important;
  //   outline: none !important;
  // }
  &:visited {
    border-radius: 1.5em !important;
    outline: none !important;
  }
}
/* specifically for content-image-overlay - panel - height 100 */
.grid-item .content-image-overlay.active,
.grid-area .content-image-overlay.active {
  height: 100%;
  opacity: 1;
}
/* specifically for content-image-overlay - symbol is arrow up - rotate 180 */
.content-image-overlay:active button.content-activate-symbol {
  transition: transform .5s;
  transform: rotate(180deg);
}
/* specifically for content-image-overlay - ACTIVE - symbol is arrow down - rotate 0 */
.content-image-overlay.active button.content-activate-symbol.active {
  background-repeat: no-repeat;
  background-position: .5rem .5rem;
  background-size: 1.125rem;
  transition: transform .5s;
  transform: rotate(180deg);
}
button.content-activate-symbol:focus, button.content-activate-symbol.active:focus {
  outline: 3px solid #febc18;
  outline-offset: -3px;
}
button.content-activate-symbol.active {
  transition: transform .5s;
  transform: rotate(180deg);
  background-color: rgba(0,0,0,.85);
  background-image: url('https://www.luc.edu/media/luc-template-2020/images/ui-arrow-up@2x.png');
  background-repeat: no-repeat;
  background-position: .5rem .5rem;
  background-size: 1.125rem;
  cursor: pointer;
}
/* specifically for + plus symbol */
button.content-activate-symbol.active.activate-symbol-plus {
  transform: rotate(0deg);
}
button.content-activate-symbol.activate-symbol-plus {
  width: 2rem !important;
  height: 2rem !important;
  background-image: url('https://www.luc.edu/media/luc-template-2020/images/ui-plus@2x.png');
  background-position: .5rem .5rem;
  background-size: 1rem;
  background-color: $maroon;
  border: none !important;
}
/* specifically for x close symbol */
button.content-activate-symbol.activate-symbol-close {
  background-image: url('https://www.luc.edu/media/luc-template-2020/images/ui-plus@2x.png');
  background-position: .5rem .5rem;
  background-size: 1rem;
}

/* center alignment of symbol */

#panel-image-highlights .content-activate-symbol,
#panel-image-grid.image-grid-highlights .content-activate-symbol {
  left: calc(50% - 1.125rem);
  cursor: default;
}

#panel-image-highlights .content-activate-symbol.activate-symbol-plus,
#panel-image-grid.image-grid-highlights .content-activate-symbol.activate-symbol-plus {
  margin-bottom: .75rem;
  left: calc(50% - 1rem);
  cursor: default;
}

/* image - overlay */

#panel-image-overlay, #panel-image-overlay-resize, #panel-video-embed {
  overflow: hidden;
  .grid-wrapper-min-max {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    .grid-item {
      height: 100%;
      margin: 0;
    }
  }
  img {
    object-fit: cover;
    height: 100%;
  }
  .content-image-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    padding: 2rem;
    color: $gray-light;
    opacity: 1;
    background: $bg-overlay;
    cite a {
      font-family: 'Open Sans', sans-serif;
      font-weight: 800;
      color: $gold;
    }
  }
}
#panel-image-overlay,
#panel-image-overlay-resize {
  img {
    display: block;
    margin: 0;
    width: 100%;
    object-fit: cover;
    @include tablet-portrait {
      margin-left: -50%;
      width: 200%;
    }
    @include phone-portrait {
      margin-left: -100%;
      width: 300%;
    }
  }
  .content-image-overlay {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    blockquote {
      align-content: center;
      margin: 0 auto !important;
      padding: 0;
      width: 70%;
      height: auto;
      text-transform: none;
      border: none;
      @include phone-portrait {
        width: 100%;
        font-size: 1.25rem;
        line-height: 2;
        max-height: 300px;
        overflow: scroll;
      }
    }
  }
}

#panel-image-overlay-resize .content-image-overlay {
  padding: 3rem;
}

#panel-image-overlay-resize {
  .grid-item {
    margin: 0;
  }
  img {
    margin-bottom: 0;
  }
  .content-image-overlay blockquote {
    display: block;
    margin: 4em 10%;
    padding: 0;
    width: 80%;
    font-size: 1.85rem;
    border-top: none;
    border-bottom: none;
    @include tablet-portrait {
      margin: 2em auto;
      width: 100%;
      font-size: 1.5rem;
      line-height: 2.85rem;
    }
  }
}

/* image - highlights */

#panel-image-highlights {
  .grid-wrapper-min-max {
    grid-row-gap: .25em;
    width: 100%;
    grid-gap: 0;
    background: $maroon;
    @include phone-portrait {
      display: block;
      width: 100%;
    }
  }
  .grid-item {
    position: relative;
    margin: 0 !important;
    border: none;
  }
  img {
    display: block;
    margin: 0;
    width: 100%;
  }
  .image-highlight-wrapper {
    padding: 0;
    position: relative;
    h4 {
      font-family: 'Antenna Condensed Light', sans-serif;
      text-shadow: 0 0 .5rem rgba(0,0,0,.5);
    }
  }
  .image-highlight-teaser-panel {
    margin: 0;
    padding: 1rem 2rem 3.75rem;
    width: 100%;
    color: $gray-extra-light;
    background: $maroon;
    h6 {
      text-align: center;
      margin: 0;
    }
  }
}

/* image grid - highlights */

#panel-image-grid.image-grid-highlights {
  .grid-wrapper-min-max {
    grid-template-columns: 1fr 1fr 1fr;
    @include tablet-portrait {
      display: block;
    }
    .grid-item {
      display: none;
      .caption-overlay {
        background: rgb(0,0,0);
        background: linear-gradient(0deg, rgba(0,0,0,0.35) 0%, rgba(0,0,0,0.35) 60%, rgba(0,0,0,0.55) 85%);
      }
      .content-image-overlay {
        padding: 2.5rem;
        h6, h4, h3, p, a {
          text-align: center;
        }
      }
    }
    .grid-item:nth-child(1),
    .grid-item:nth-child(2),
    .grid-item:nth-child(3) {
      display: block;
    }
  }
  img {
    display: block;
    margin: 0;
    width: 100%;
  }
  p.caption-overlay {
    position: absolute;
    bottom: -29px;
    left: 0px;
    padding: .5rem .5rem .5rem .5rem;
    width: 100%;
    height: 100%;
    min-height: 100%;
    font-family: 'Open Sans', sans-serif;
    font-size: .75rem;
    color: $gray-extra-light;
    text-transform: uppercase;
    text-shadow: 0 0 15px $bg-overlay;
    text-align: center;
    border-radius: 0;
    background: none;
    span {
      position: absolute;
      top: 4rem;
      margin: 0 10%;
      width: 80%;
      font-family: 'Antenna Condensed Bold', sans-serif;
      font-size: 1.75rem;
      line-height: 1.25;
      color: $gray-extra-light;
      text-align: center;
      text-shadow: 0 0 .5rem rgba(0,0,0,.35);
      text-transform: none;
      @include tablet-portrait {
        font-size: 1.5rem;
      }
      @include phone-landscape {
        font-size: 1.5rem;
      }
      @include phone-portrait {
        font-size: 1.5rem;
      }
    }
    span.caption-overlay-name {
      display: none;
    }
  }
}

#panel-image-grid.image-grid-highlights {
  .grid-wrapper-min-max.two-column {
    grid-template-columns: 1fr 1fr !important;
    .grid-item:nth-child(3) {
      display: none;
    }
  }
}

/* gradient overlay */

#panel-image-highlights .grid-item .content-image-overlay {
  padding: 40% 2em 0;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.7)));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
}

#panel-image-highlights h2 {
  position: absolute;
  top: 4rem;
  font-size: 2.5rem;
  color: $gray-extra-light;
  text-align: center;
  text-shadow: 0 0 .5rem rgba(0,0,0,.35);
  text-transform: none;
  @include tablet-portrait {
    font-size: 1.85rem;
  }
  @include phone-landscape {
    font-size: 1.75rem;
  }
  @include phone-portrait {
    font-size: 2rem;
  }
}

/* image - grid */

#panel-image-grid {
  background-image: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-color: $gray-extra-light;
  .grid-wrapper-min-max {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-template-rows: auto;
    grid-auto-flow: row;
    grid-auto-rows: auto;
    grid-row-gap: .25em;
    width: 100%;
    grid-gap: 0;
  }
  .grid-item {
    position: relative;
    margin: 0 !important;
    border: solid none;
  }
  img {
    margin: 0;
  }
  p.caption-overlay {
    position: absolute;
    bottom: -29px;
    left: 0px;
    padding: .5rem .5rem .5rem .5rem;
    width: 70%;
    height: auto;
    min-height: 75px;
    font-family: 'Open Sans', sans-serif;
    font-size: .75rem;
    color: $gray-extra-light;
    text-transform: uppercase;
    text-shadow: 0 0 15px $bg-overlay;
    border-radius: 0 10px 0 0;
    background: rgba(0,0,0,.35);
    span {
      display: block;
      width: 100%;
      font-family: 'Open Sans', sans-serif;
      line-height: 1.75;
    }
    span.caption-overlay-name {
      font-family: 'Open Sans', sans-serif;
      font-weight: 700;
      font-size: .85rem;
    }
    a {
      color: lighten(gold, 10%);
    }
  }
}

/* image grid - image only */

/* border */

#panel-image-grid.image-only .grid-item {
  overflow: hidden;
  height: 100%;
  border: 0;
  @include phone-portrait {
    height: 20vh;
  }
  img {
    margin: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    cursor: pointer;
  }
}

/* hide images */

@media only screen and (max-width: 1500px) {
  .grid-item.hide-1500 { display: none; }
}
@media only screen and (max-width: 1200px) {
  .grid-item.hide-1200 { display: none; }
}
@media only screen and (max-width: 736px) {
  .grid-item.hide-736 { display: none; }
}

/* image-grid - image only - responsive grid-item */

#panel-image-grid.image-only {
  background-image: none;
}

#panel-image-grid.image-only .caption-overlay,
#panel-image-grid.image-only .caption-overlay-name,
#panel-image-grid.image-only .content-activate-symbol,
#panel-image-grid.image-only .content-image-overlay {
  display: none;
}

#panel-image-grid.image-only .grid-wrapper-min-max {
  grid-gap: .5rem;
}

#panel-image-grid.image-only .grid-wrapper-min-max {
  grid-template-columns: repeat(auto-fit, minmax(19%, 1fr));
}
@media only screen and (max-width: 1500px) {
#panel-image-grid.image-only .grid-wrapper-min-max {
  grid-template-columns: repeat(auto-fit, minmax(24%, 1fr));
  }
}
@media only screen and (max-width: 1200px) {
#panel-image-grid.image-only .grid-wrapper-min-max {
  grid-template-columns: repeat(auto-fit, minmax(32%, 1fr));
  }
}
#panel-image-grid.image-only .grid-wrapper-min-max {
  @include phone-portrait {
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  grid-auto-rows: 20vh;
  grid-row: span 1;
  }
}

/* image-grid-area */

#panel-image-grid-area {
  .grid-wrapper-grid-area {
    display: -ms-grid;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr !important;
    -ms-grid-columns: 1fr 1fr 1fr 1fr !important;
    grid-template-rows: auto;
    grid-auto-flow: row;
    -ms-grid-rows: auto;
    grid-auto-rows: auto;
    grid-row-gap: .25em;
    width: 100%;
    grid-gap: 0;
    .grid-area {
    position: relative;
    border: solid 1px red;
    }
    img {
      margin: 0 !important;
    }
    @include phone-portrait {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}
.grid-area-one {
  grid-area: 1 / 1 / span 2 / span 3;
  @include tablet-portrait {
    grid-area: 1 / 1 / span 3 / span 4;
  }
}
.grid-area-two {
  grid-area: 1 / 4 / span 1 / span 1;
  @include tablet-portrait {
    grid-area: 4 / 1 / span 2 / span 2;
  }
}
.grid-area-three {
  grid-area: 2 / 4 / span 1 / span 1;
  @include tablet-portrait {
    grid-area: 4 / 3 / span 2 / span 2;
  }
}
.grid-area-four {
  grid-area: 3 / 1 / span 3 / span 1;
  @include tablet-portrait {
    grid-area: 6 / 1 / span 1 / span 1;
  }
}
.grid-area-five {
  grid-area: 3 / 2 / span 2 / span 3;
  @include tablet-portrait {
    grid-area: 6 / 2 / span 1 / span 3;
  }
}
.grid-area-one,
.grid-area-two,
.grid-area-three,
.grid-area-four,
.grid-area-five {
  @include phone-portrait {
    display: block;
    width: 100%;
    height: auto;
  }
}

/* image grid gallery */

#panel-image-grid-mason-gallery {
  display: block;
  padding: 0;
  text-align: center;
}

#panel-image-grid-mason-gallery .grid-wrapper-grid-wrapper {
  display: grid;
  grid-template-columns: repeat(6,1fr);
  grid-auto-rows: 20vw;
  grid-gap: .5rem;
  grid-auto-flow: dense;
  @include tablet-portrait {
    grid-template-columns: repeat(4,1fr);
  }
  @include phone-portrait {
    grid-template-columns: repeat(1,1fr);
  }
}
.gallery-item {
  position: relative;
  width: 100%;
  height: 100%;
  background: $gray-extra-dark;
  h5 {
    display: none;
    position: absolute;
    top: 10%;
    margin: 0;
    font-size: 1.125rem;
    line-height: rvr(1.6);
    text-align: center;
    text-shadow: 0 0 3rem rgba(0,0,0,1);
    color: $white;
    text-transform: none;
  }
  .image {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    img {
      margin: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      cursor: pointer;
    }
  }
  .caption {
    display: absolute;
    margin: 0 auto;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    color: $gray-extra-light;
    pointer-events: none;
    opacity: 0;
    z-index: +4;
    p {
      position: absolute;
      top: 40%;
      margin: 0 0 .75rem;
      padding: 0 1rem;
      width: 100%;
      font-family: 'Open Sans', sans-serif;
      letter-spacing: .0125rem;
      word-spacing: .0125rem;
      text-align: center;
      text-shadow: 0 0 20px rgba(0,0,0,.35);
      color: $white;
      span {
        display: block;
        margin: 0;
        padding: 0 1rem;
        width: 100%;
        a {
          color: $gold;
        }
      }
    }
  }
  /* all hover is through gallery-item */
  &:hover {
    opacity: 1;
    .image {
      img {
        display: block;
        margin: 0;
        width: 100%;
        opacity: .7;
        /* transform:scale(1.0125); */
        filter: none;
        -webkit-filter: grayscale(100%);
        -moz-filter:    grayscale(100%);
        -ms-filter:     grayscale(100%);
        -o-filter:      grayscale(100%);
        cursor: pointer;
      }
    }
    .caption {
      display: block;
      margin: 0 auto;
      padding: 0;
      width: 100%;
      height: 100%;
      opacity: 1;
      backdrop-filter: blur(3px);
      -webkit-backdrop-filter: blur(3px);
      p {
        top: 40%;
        animation: caption-fade-in .3s;
        animation-timing-function: ease;
        a.cta {
          font-style: normal !important;
          color: $white !important;
        }
      }
    }
  }
}

.w-1 {
  grid-column: span 1;
}
.w-2 {
  grid-column: span 2;
}
.w-3 {
  grid-column: span 3;
}
.w-4 {
  grid-column: span 4;
}
.w-5 {
  grid-column: span 5;
}
.w-6 {
  grid-column: span 6;
}
.w-1, .w-2, .w-3, .w-4, .w-5, .w-6 {
  @include tablet-portrait {
    grid-column: span 2;
  }
}
.w-1, .w-2, .w-3, .w-4, .w-5, .w-6 {
  @include phone-portrait {
    grid-column: span 1;
  }
}

.h-1 {
  grid-row: span 1;
}
.h-2 {
  grid-row: span 2;
}
.h-3 {
  grid-row: span 3;
}
.h-4 {
  grid-row: span 4;
}
.h-5 {
  grid-row: span 5;
}
.h-6 {
  grid-row: span 6;
}

.h-1, .h-2, .h-3, .h-4, .h-5, .h-6 {
  @include tablet-portrait {
    grid-row: span 2;
  }
}

@keyframes caption-fade-in {
  0% {
    top: 25%;
    opacity: 0;
  }
  100% {
    top: 40%;
    opacity: 1;
  }
}

/* modaal - most styles are in _accessible-modaal-aria.scss */
/* adjustments */

.modaal-container {
  width: 80% !important;
}

button.modaal-close {
	position: fixed;
	right: 20px;
	top: 20px;
	width: 50px;
	height: 50px;
  color: $white;
	background: none;
  border: none !important;
  &:hover {
    color: $gray-extra-light !important;
    background: $gold;
  }
}

.modaal-close:before, .modaal-close:after,
.modaal-close:focus:before, .modaal-close:focus:after,
.modaal-close:hover:before, .modaal-close:hover:after {
  top: 15px;
  left: 23px;
  background: $white;
}

/* video embed */

#panel-video-embed {
  position: relative;
  background: $brick;
  overflow: hidden;
  .grid-wrapper-min-max {
    position: relative;
    margin: 3rem auto;
    padding: 0;
    width: 80%;
    justify-content: center;
    align-items: center;
    grid-gap: 2rem;
  }
  .grid-item {
    margin: 0;
    z-index: 1;
  }
  .content-image-overlay {
    padding: 0;
    background: $bg-overlay-extra-dark;
    .container {
      height: 100%;
    }
  }
  .background-image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    img {
      margin: 0;
      padding: 0;
      object-fit: cover;
    }
  }
}

/* background image - display none */
#panel-video-embed.hide-background-image {
  .content-image-overlay,
  .background-image {
    display: none;
  }
}

#panel-video-embed .content-caption {
  display: block;
  padding: 2rem;
  background: $gray-extra-dark;
  height: 100%;
    @include tablet-portrait {
      padding: 1.5rem;
    }
  h4 {
    margin-top: 0;
    margin-bottom: rvr(.5);
    font-size: 1.65rem;
    line-height: rvr(1.65);
  }
  h4, p, p strong, p em, p em strong {
    color: $gray-light;
  }
  p {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.135rem;
  }
  a, a.cta {
    margin-bottom: .25rem;
    font-size: 80%;
    color: $gold;
    &:hover {
      color: lighten($gold,10%);
    }
  }
}

.video-wrapper {
    position: relative;
    display: block;
    margin: 0;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
}

.video-wrapper iframe {
   position: absolute;
   left: 0;
   right: 0;
   top: 0;
   height: 100%;
   width: 100% !important;
   min-width: 100% !important;
}

/* pagination */

#panel-pagination #content-pagination {
  padding: 0;
}

#panel-pagination {
  nav {
    float: none;
    background: none;
  }
  .grid-wrapper-min-max {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr !important;
    grid-template-columns: repeat(auto-fit, minmax(30px, 1fr)) !important;
    grid-template-rows: auto;
    grid-auto-flow: row;
    -ms-grid-rows: auto;
    grid-auto-rows: auto;
    grid-row-gap: 0;
    grid-gap: 0;
    margin: 0 auto;
    width: 100%;
    height: 2rem;
      .grid-item {
        display: flex;
        box-sizing: border-box;
        padding: 0;
        margin: 0;
      a {
        box-sizing: border-box;
        padding: .65rem 1rem .45rem;
        width: 100%;
        height: 100%;
        font-family: 'Open Sans', sans-serif;
        line-height: 95%;
        color: $gray-extra-dark;
        text-align: center;
        text-decoration: none;
        border-left: solid 1px darken($gray-light, 15%);
        background: $gray-light;
        transition: .2s;
        &:hover {
          font-family: 'Open Sans', sans-serif;
          font-weight: 600;
          background: darken($gray-light, 15%);
        }
      }
      a.active, a:active {
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        color: $white;
        background: $maroon;
        border: 1px solid $maroon;
        &:hover {
          font-weight: 600;
          background: $brick;
        }
      }
    }
  }
}

#panel-pagination #previous-pages.grid-item a,
#panel-pagination #next-pages.grid-item a {
  background-color: darken($gray-light,10%);
  &:hover {
    background: darken($gray-light,15%);
  }
}

#panel-pagination #previous-pages.grid-item a {
  border-radius: .25rem 0 0 .25rem;
}

#panel-pagination #next-pages.grid-item a {
  border-radius: 0 .25rem .25rem 0;
}

#panel-pagination a:hover:not(.active) {background-color: $maroon;}

/* pagination breadcrumb */

#panel-pagination #content-pagination-breadcrumbs {
  padding: 0 0 2rem 0;
}

#panel-pagination #content-pagination-breadcrumbs .breadcrumb {
  margin: 0;
  padding: 0;
  background-color: none;
}

#panel-pagination #content-pagination-breadcrumbs {
  .grid-wrapper-min-max {
    display: inline;
    border: none;
    background: none;
    a {
      display: inline;
      margin: 0 0 .25rem;
      padding: 0;
      font-family: 'Antenna', sans-serif;
      font-size: .75rem;
      color: $gray;
      text-transform: uppercase;
      border: none;
      background: none;
      &:last-of-type {
        color: $maroon;
      }
      &::before {
        display: inline;
        margin: 0 .25rem 0 0;
        padding: 0;
        color: $gray;
        content: "/";
        text-indent: 0;
      }
      &:hover {
        color: $maroon;
        background: none;
      }
    }
  }
}

#content-pagination-breadcrumbs a.active {
  color: $maroon !important;
}

/* image - inline-icon */

img.inline-icon {
  margin: 0 .5rem -1.125rem 0;
  width: 3rem !important;
  height: 3rem !important;
}

/* calendar card */

#panel-events-news .grid-wrapper-min-max {
  grid-gap: 0;
}

#panel-events-news .grid-wrapper-min-max .grid-item {
  margin: 0 !important;
  padding: 0 2em;
  border-left: solid 1px $gray;
  @include phone-portrait {
    border: none;
  }
}

#panel-events-news .grid-wrapper-min-max .grid-item:first-of-type {
  border-left: none !important;
}

#panel-events-news {
  h6 {
    margin-bottom: .25rem;
  }
  h4 {
    margin: 0 0 .25rem;
    font-size: 1.45rem;
    line-height: rvr(1.5);
    a {
      text-decoration: none;
    }
  }
  h5 {
    font-size: 1.45rem;
  }
  a.cta {
    font-size: .95rem;
  }
  .grid-wrapper-min-max .grid-item h5:first-of-type {
    margin-top: 0;
  }
  .grid-wrapper-min-max .grid-item .cta:last-of-type {
    margin-bottom: 0;
  }
}

.calendar-card {
  grid-column: col 2 / span 2;
  grid-row: row 1;
  display: grid;
  grid-gap: 0;
  grid-template-columns: 6rem 1fr;
}

.calendar-card-date {
  margin: 0 1.25rem 0 0;
  grid-column: 1;
  grid-row: 1;
  border-radius: .25rem;
  background: none;
  h6 {
    margin: 0;
    font-family: 'Antenna Condensed Bold', sans-serif;
    text-align: center;
  }
  h6 small {
    font-size: 1rem;
  }
  .calendar-card-date-day {
    padding: .5rem 0 .125rem;
    color: $gray-extra-light;
    text-transform: uppercase;
    border-radius: .25rem .25rem 0 0;
    background: $maroon;
    h6 {
      color: $gray-extra-light !important;
    }
  }
  .calendar-card-date-number {
    padding: .5rem 0 .125rem;
    border-radius: 0 0 .25rem .25rem;
    background: lighten($gray-light,10%);
  }
}

.calendar-card-content {
  grid-column: 2;
  grid-row: 1;
}

.calendar-card-content > div > p {
  margin: 0 0 .5rem !important;
  margin-bottom: calc(var(--baseline) * .25 * 1rem);
}

/* END - CSS */
