/* styles panels */

//@import 'variables';
//@import 'mixins';
@import 'mixins/breakpoints';
@import '../scss-typography/custom-variables';
@import '../scss-typography/vertical-rhythm';
@import 'accessible-accordion-aria';
@import 'accessible-tabs-aria';
@import 'accessible-modaal-aria';
@import 'aos';
@import 'audio';
@import 'cards';
@import 'forms';
@import 'tables';
@import 'tables-adjust';
@import 'charts';
@import 'charts-pie';
@import 'charts-bar';
@import 'charts-column';
@import 'charts-progress';
@import 'charts-donut';
@import 'panels';
@import 'carousel';
@import 'carousel-scroll';