/* table - reset */

table {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100% !important;
  @include phone-portrait {
    display: block;
  }
}

tbody, th, tr {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: auto;
  @include phone-landscape {
    float: left;
  }
}

table, tbody, th, tr, td, tr.column, .column {
  border: none;
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-spacing: 0;
  background: none;
  background-color: none;
}

/* table - style */

table {
  margin: 1rem 0 2rem 0;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  overflow: scroll;
  caption {
    display: block;
    width: 100%;
  }
  @include phone-landscape {
    display: block;
    margin: 20px 0 0 0;
    height: 100%;
    overflow: visible;
    tbody {
      width: 100%;
    }
    span {
      display: block;
      margin: -.5rem -.5rem .5rem;
      padding: .5rem;
      width: calc(100% + 1rem);
      font-family: 'Antenna Condensed Bold', sans-serif;
      font-size: 1rem;
      color: $white;
      text-transform: uppercase;
      background-color: $gray-dark;
    }
    tr td:first-of-type p {
      color: $white;
      font-weight: 700;
    }
    tr td:first-of-type p strong {
      color: $white;
      font-weight: 700;
    }
    td a {
      word-wrap: break-word;
    }
    td:last-of-type span:last-of-type {
      margin: .5rem 0 -.5rem -.5rem;
    }
  }
}

.grid-item:has(table) {
  overflow-x: scroll;
}

th, td {
  border: solid 1px $gray;
  border-collapse: collapse;
  padding: 0.75rem;
  font-family: 'Open Sans', sans-serif;
  text-align: left;
  @include phone-landscape {
    display: block;
    box-sizing: border-box;
    padding: .5rem;
    width: 100%;
    background: #eeeeee;
  }
}

td:has(img) {
  padding: 1rem;
}

table td {
  img {
    display: block;
    margin: 0 !important;
    width: auto;
    height: auto;
    max-width: 8rem;
  }
  span {
    display: none;
    @include tablet-portrait {
      display: block;
    }
  }
}

th {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  color: $gray-extra-light;
  text-transform: none;
  background: $gray-dark;
  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4),
  &:nth-child(5),
  &:nth-child(6),
  &:nth-child(7) {
    border-left: none;
    border-left: solid 1px $gray-extra-light;
  }
  p, p strong {
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.35;
    line-height: rvr(1.35) !important;
    margin: 0;
    color: $gray-extra-light;
  }
}

tr th:nth-child(1),
tr th:nth-child(1) p,
tr th:nth-child(1) p strong {
  color: $white;
}

thead tr {
  @include phone-landscape {
    display: none;
  }
}

tr {
  @include phone-landscape {
    display: block;
    margin: 1.5rem 0;
    float: left;
    width: 100%;
  }
  a {
    color: $maroon;
  }
}

th.span.sub-category,
tr:first-child th.span.sub-category {
  color: inherit !important;
  background: darken($gray,1%) !important;
}

tr:first-child th:first-child,
#panel-table-accordion h4 span.table-accordion-row-header {
  letter-spacing: -.025rem;
}

tbody tr:first-child th:first-child {
  color: $black;
  background: $gray-light;
}

tr:first-child th:first-child,
tr:not(:first-child):not(:last-child) th {
  border-bottom-color: $gray !important;
}

tr:not(:first-child) th {
  color: $black;
  background: $gray-light;
}

caption {
  margin-bottom: 1rem;
  width: 100%;
  font-style: italic;
  text-align: left;
}

td {
@include phone-landscape {
  margin: 0;
  }
}

table > tbody > tr > td {
  a {
    color: $maroon;
    font-weight: 700;
  }
}

table > tbody > tr > td:nth-child(1) {
@include phone-landscape {
  padding: .5rem;
  background: $brick;
  font-weight: 700;
  color: $white;
  border: none;
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
    span {
      background: $maroon;
      color: $gray-extra-light;
    }
    a {
      color: $white;
      font-weight: 700;
    }
  }
}


/* table accordion and accordion with nested table */

td p, td ul, #panel-table-accordion ul, #panel-table-accordion p {
  margin: .5rem 0 !important;
}

td p, #panel-table-accordion p {
  &:first-of-type { margin-top: 0 !important; }
  &:last-of-type { margin-bottom: 0 !important; }
}

table tbody th, table tbody th strong,
td, td strong, td p, td p strong, td ul li, td ul li strong,
#panel-table-accordion ul li, #panel-table-accordion p, #panel-table-accordion ul li strong, #panel-table-accordion p strong,
.minimalist-accordion__panel table tbody th, .minimalist-accordion__panel table tbody th strong,
.minimalist-accordion__panel table tbody tr td p, .minimalist-accordion__panel table tbody tr td p strong {
  font-size: .85rem !important;
  letter-spacing: -.025rem;
  text-align: left;
  line-height: rvr(1.125) !important;
  a { line-height: rvr(1.125) !important; }
}

td ul li, #panel-table-accordion ul li {
  margin: .5rem 0 !important;
}

td ul li, #panel-table-accordion ul li {
  margin-left: 1.5rem !important;
  text-indent: -1.5rem;
  list-style-type: square;
  list-style-position: inside;
}

#panel-table-accordion {
  @include phone-portrait {
    margin: 0 auto;
  }
}

#panel-table-accordion .caption > p {
  margin-bottom: 1rem;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-style: italic;
  line-height: rvr(1.25);
  text-align: left;
}

#panel-table-accordion button.minimalist-accordion__header,
#panel-table-accordion button.minimalist-noanim-accordion__header,
#panel-table-accordion button.minimalist-css-accordion__header {
  padding: 0;
  border-right: solid 1px $gray !important;
  @include phone-portrait {
    border-left: solid 1px $gray !important;
  }
}

#panel-table-accordion h4 button,
#panel-table-accordion h4.table-accordion-header {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr !important;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-template-rows: auto;
  grid-auto-flow: row;
  padding: 0;
  &:after {
    float: right;
  }
  @include phone-portrait {
    display: block !important;
    width: 100%;
    text-align: left;
  }
}

#panel-table-accordion h4 button span:first-of-type {
  border-left: solid 1px $gray;
  @include phone-portrait {
    padding: .75rem 1rem .5rem;
    text-align: left;
    border-left: 0;
    border-top: solid 1px $gray;
    border-right: 0;
  }
  &:before {
    display: none;
  }
}

#panel-table-accordion h4 button span {
  @include phone-portrait {
    display: block;
    position: relative;
    padding-left: 50%;
    padding: 1rem 2.25rem .75rem 50%;
    width: 100%;
    text-align: right;
    border-bottom: solid 1px $gray;
    border-right: 0;
    &:before {
      display: none;
    }
    &:last-of-type {
      border-bottom: none;
    }
  }
}

#panel-table-accordion h4.table-accordion-header {
  margin: 0;
  font-size: inherit;
  color: $gray-extra-light;
  text-transform: uppercase;
  background: $maroon;
}

#panel-table-accordion h4.table-accordion-header::after {
  /* see accessible-accordion-aria.scss */
}

#panel-table-accordion h4 span,
#panel-table-accordion h4.table-accordion-header span,
#panel-table-accordion .minimalist-accordion__header::after,
#panel-table-accordion .minimalist-noanim-accordion__header::after,
#panel-table-accordion .minimalist-css-accordion__header::after {
  display: inline-block;
  margin: 0 !important;
  padding: .5em 1em;
  height: 100%;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  text-transform: none;
  border-right: solid 1px $gray;
  @include phone-portrait {
    padding: 1rem;
  }
}

 #panel-table-accordion .minimalist-accordion__header::after {
   @include phone-portrait {
     display: block !important;
     margin-top: -2.575rem !important;
     padding: 1rem;
     height: 2rem;
   }
 }

#panel-table-accordion h4.table-accordion-header span {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  border-right: solid 1px $gray-extra-light;
  @include phone-portrait {
    display: none !important;
  }
}

#panel-table-accordion h4 button span:before {
  @include phone-portrait {
    display: block;
    position: absolute;
    box-sizing: border-box;
    top: 0;
    left: 0;
    padding: 1rem 1rem .75rem;
    width: 50%;
    color: #eeeeee;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    border-bottom: solid 1px $gray-light;
    background: #555555;
  }
}

#panel-table-accordion .minimalist-accordion__header::after,
#panel-table-accordion .minimalist-noanim-accordion__header::after,
#panel-table-accordion .minimalist-css-accordion__header::after {
  padding: 0 1rem 0 0 !important;
  justify-self: right;
  background-size: 40%;
  background-position: 50% 50%;
  border-right: none;
}

#panel-table-accordion h4 span.table-accordion-row-header {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  background: $gray-light;
}
