/* --------------- styles for accordion ----------------------- */


/* accordion */

#panel-accordion {
  margin: 2rem auto;
}

/* just for example as nested accordion */
[data-accordion-prefix-classes="minimalist-css"] {
  margin-top: 1rem;
  width: 100%;
}

#js-accordion__panel .minimalist-accordion__panel [data-accordion-prefix-classes="minimalist-css"] {
  margin-top: 1rem;
}

h4.minimalist-accordion__title,
h4.minimalist-noanim-accordion__title,
h4.minimalist-css-accordion__title,
h3.minimalist-accordion__title,
h3.minimalist-noanim-accordion__title,
h3.minimalist-css-accordion__title {
  margin: 0 !important;
  padding: 0;
  width: 100%;
}

h4.js-accordion-no-panel,
button.minimalist-accordion__header,
button.minimalist-noanim-accordion__header,
button.minimalist-css-accordion__header {
  margin: 0;
  padding: 1rem 2.5rem 1rem 1rem;
/* padding: .85rem 2rem 1rem 1rem; Sentence Case */
  color: $black !important;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.25rem;
  letter-spacing: -.05rem !important;
  text-transform: none;
  border-radius: 0 !important;
  border: none !important;
  border-bottom: solid 1px $gray !important;
  outline: none;
  @include phone-portrait {
    font-size: 1.125rem;
  }
}

h4.js-accordion-no-panel {
  padding: 1.125rem 2rem 1rem 1rem !important;
/*  padding: 1rem 2rem 1rem 1rem !important; Sentence Case */
  text-transform: none;
  a.cta {
    display: inline;
    margin: 0 0 0 .25rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: inherit;
  }
}

h4.js-accordion-no-panel,
.minimalist-accordion__header,
.minimalist-noanim-accordion__header,
.minimalist-css-accordion__header {
  display: block;
  margin: 0;
  padding: .25em 0;
  width: 100%;
  min-width: 100%;
  /** fix typo inputs **/
  font-family: inherit;
  font-size: 1.25rem;
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  border: 0;
  background: none;
  cursor: pointer;
  a.cta {
    font-size: 85%;
    font-weight: 800;
  }
  @include phone-portrait {
    font-size: 1.125rem;
  }
}

.minimalist-css-accordion__header {
  font-size: 1.75em;
}

button.minimalist-accordion__header,
button.minimalist-noanim-accordion__header,
button.minimalist-css-accordion__header {
  &:hover {
    background: $gray-extra-light !important;
  }
  &:focus {
    outline: solid $maroon;
    color: #ffffff;
  }
}

#panel-accordion [aria-expanded=true].minimalist-accordion__header,
#panel-accordion [aria-expanded=true].minimalist-noanim-accordion__header,
#panel-accordion [aria-expanded=true].minimalist-css-accordion__header {
  color: $white !important;
  background: $maroon !important;
  font-weight: 600;
  &:hover {
    color: $white;
    background: $gray !important;
  }
}

#side-nav button.minimalist-accordion__header:focus,
#side-nav button.minimalist-noanim-accordion__header:focus,
#side-nav button.minimalist-css-accordion__header:focus {
  outline: solid $gold;
}

/* state of accordion header - caret image */

.minimalist-accordion__header::after,
.minimalist-noanim-accordion__header::after,
.minimalist-css-accordion__header::after,
#panel-table-accordion h4.table-accordion-header::after {
  float: right;
  display: inline-block;
  margin: .3rem -1rem 0 0;
  width: 1.125rem;
  height: 1.125rem;
  content: '';
  background-image: url('https://www.luc.edu/media/luc-template-2020/images/ui-arrow-carrousel-next-dark@2x.png');
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: 80%;
  transform: rotate(0deg);
}

#panel-table-accordion h4.table-accordion-header::after {
  background-image: none;
}

[aria-expanded="true"].minimalist-accordion__header::after,
[aria-expanded="true"].minimalist-noanim-accordion__header::after,
[aria-expanded="true"].minimalist-css-accordion__header::after {
  margin: .4rem -1.25rem 0 0;
  transform: rotate(90deg);
  transform-origin: 50% 50%;
  filter: invert(1);
}

/* accordion panel - content */


nav#secondary .minimalist-accordion__panel,
.minimalist-accordion__panel {
  display: block;
  margin: 2em;
  padding: 0;
  height: auto;
  visibility: visible;
  overflow: hidden;
  opacity: 1;
  /* animation */
  -webkit-transition: visibility 0s ease, max-height 1s ease, opacity 1s ease;
  transition: visibility 0s ease, max-height 1s ease, opacity 1s ease;
  -webkit-transition-delay: 0;
  transition-delay: 0;
  /* padding: 2em; - use this panel with background color $gray-extra-light */
}

.minimalist-accordion__panel {
  margin: 0;
  padding: 2rem;
  background: $gray-extra-light;
}

/* This is the hidden state */

[aria-hidden=true].minimalist-accordion__panel,
nav#secondary [aria-hidden=true].minimalist-accordion__panel {
  display: block;
  margin: 0;
  padding: 0;
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: padding 1s ease-out;
  transition: padding 1s ease-out;
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
}

.minimalist-css-accordion__panel {
  display: block;
  margin: 0;
  padding: 0;
  max-height: 400rem;
  /* magic number for max-height = enough height */
  opacity: 1;
  visibility: visible;
  overflow: hidden;
  -webkit-transition: visibility 0s ease, max-height 1s ease, transform 1s ease, opacity 1s ease;
  transition: visibility 0s ease, max-height 1s ease, transform 1s ease, opacity 1s ease;
  transform: scaleY(1);
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.js-accordion__panel.minimalist-accordion__panel p:last-of-type {
  margin-bottom: 0 !important;
}

.js-accordion__panel.minimalist-accordion__panel p:first-of-type {
  margin-bottom: 1rem !important;
}

.js-accordion__panel.minimalist-accordion__panel h5:first-of-type {
  margin-top: 0rem !important;
}

/* This is the hidden state */
/*
[aria-hidden=true].minimalist-css-accordion__panel {
  display: block;
  margin: 0;
  padding: 0;
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  transform: scaleY(0);
  -webkit-transition-delay: 1s, 0s, 0s, 0s;
  transition-delay: 1s, 0s, 0s, 0s;
}
*/

.minimalist-noanim-accordion__panel {
  display: block;
}

[aria-hidden=true].minimalist-noanim-accordion__panel {
  display: none;
}

/* Panel portrait images - need image-portrait-class */

#panel-accordion .js-accordion__panel img.image-portrait,
#panel-accordion .js-accordion__panel img.image-portrait[style] {
  margin: 0 0 1rem;
  width: 16.875rem !important;
  max-width: 16.875rem !important;
  height: auto;
  @include phone-landscape {
    width: 100% !important;
    max-width: 100% !important;
  }
}
